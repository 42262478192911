import React, { useState } from 'react';

export default function Home({ commands }) {
    const [isTerminalOpen, setIsTerminalOpen] = useState(false);
    const [openCommand, setOpenCommand] = useState(null);

    const toggleTerminal = () => {
        setIsTerminalOpen(!isTerminalOpen);
    };

    const toggleCommand = (command) => {
        setOpenCommand(openCommand === command ? null : command);
    };

    const commandHelpText = Object.keys(commands).map(cmd => (
        <div key={cmd}>
            <br></br>
            <button onClick={() => toggleCommand(cmd)}>
                {openCommand === cmd ? 'Masquer' : 'Afficher'} {cmd}
            </button>
            {openCommand === cmd && (
                <div className="command-info">
                    <p>{commands[cmd].description}</p>
                </div>
            )}
        </div>
    ));

    return (
        <div className='page'>
            <h2>Introduction</h2>
            <br></br>
            <div className="contact-info">
                <p>Bienvenue sur mon portfolio. Informaticien passionné, je suis un explorateur des possibles, toujours à la recherche de nouveaux horizons créatifs. À travers mes projets, je développe des sites web innovants pour donner vie à mes idées. Plongez dans mon univers où la technologie rencontre l'imagination, et découvrez comment je transforme les concepts en réalités numériques.</p>
            </div>
            <br></br>
            <h2>Utiliser le terminal</h2>
            <br></br>
            <button onClick={toggleTerminal}>
                {isTerminalOpen ? 'Masquer' : 'Afficher'} les infos du terminal
            </button>
            <br></br>
            {isTerminalOpen && (
                <div className="contact-info">
                    <br></br>
                    <p>Le terminal permet d'exécuter des commandes pour naviguer sur le site.</p>
                    <p>Utilisation : tapez "navigate &lt;page&gt;" pour aller vers une page spécifique.</p>
                    <p>Par exemple : "navigate contact" pour aller à la page de contact.</p>
                    <div className="help-section">
                        <h3>Commandes disponibles</h3>
                        {commandHelpText}
                    </div>
                    <br></br>
                </div>
            )}
        </div>
    );
}