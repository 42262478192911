import React, { useState, useRef, useEffect } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    applyNodeChanges,
    applyEdgeChanges,
    Background,
} from 'reactflow';
import 'reactflow/dist/style.css';

const nodeStyle = {
    backgroundColor: 'transparent',
    color: '#0f0',
    border: '2px solid #0f0',
    borderRadius: '10px',
    padding: '10px'
};

const selectedNodeStyle = {
    backgroundColor: '#0f0',
    color: 'black',
    borderRadius: '10px',
    padding: '10px',
    border: '2px solid #0f0'
};

let id = 9;
const getId = () => `dndnode_${id++}`;

function convertToInitialNodes(careerNodes) {
    return careerNodes.map((node, index) => ({
        id: (index + 1).toString(),
        data: { label: node.label, description: node.description },
        position: { x: 100, y: index * 100 },
        type: 'default',
        style: nodeStyle,
        draggable: true,
        connectable: false,
    }));
}

function generateEdges(nodes) {
    return nodes.map((node, index) => {
        if (index === nodes.length - 1) return null;
        return {
            id: `${node.id}-${nodes[index + 1].id}`,
            source: node.id,
            target: nodes[index + 1].id,
            markerEnd: { type: 'arrowclosed' }
        };
    }).filter(edge => edge !== null);
}

export default function CareerPath({ careerNodes }) {
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        const initialNodes = convertToInitialNodes(careerNodes);
        setNodes(initialNodes);
        setEdges(generateEdges(initialNodes));
    }, [careerNodes]);

    const onConnect = (params) => setEdges((eds) => addEdge({ ...params, markerEnd: { type: 'arrowclosed' } }, eds));
    const onNodesChange = (changes) => setNodes((nds) => applyNodeChanges(changes, nds));
    const onEdgesChange = (changes) => setEdges((eds) => applyEdgeChanges(changes, eds));

    const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event) => {
        event.preventDefault();

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        const newNode = {
            id: getId(),
            type: 'default',
            position,
            data: { label: `${type} node` },
            style: nodeStyle,
            draggable: true,
            connectable: false
        };

        setNodes((nds) => {
            const updatedNodes = nds.concat(newNode);
            setEdges(generateEdges(updatedNodes));
            return updatedNodes;
        });
    };

    const onNodeClick = (_, node) => {
        if (selectedNode && selectedNode.id === node.id) {
            setSelectedNode(null);
        } else {
            setSelectedNode(node);
        }
    };

    const onWrapperClick = (event) => {
        if (!isDragging && selectedNode && !event.target.closest('.react-flow-node') && !event.target.closest('.description-panel')) {
            setSelectedNode(null);
        }
    };

    const onPanelClick = (event) => {
        event.stopPropagation();
    };

    const onNodeDragStart = () => {
        setIsDragging(true);
    };

    const onNodeDragStop = () => {
        setIsDragging(false);
    };

    return (
        <ReactFlowProvider>
            <div style={{ height: '100%', width: '100%', position: 'relative' }} className="react-flow-wrapper" onClick={onWrapperClick}>
                {selectedNode && (
                    <div
                        className="description-panel"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            width: '300px',
                            padding: '10px',
                            backgroundColor: '#000',
                            color: '#0f0',
                            border: '1px solid #0f0',
                            borderRadius: '5px',
                            zIndex: 10,
                        }}
                        onClick={onPanelClick}
                    >
                        <h2>Description</h2>
                        <p>{selectedNode.data.description}</p>
                    </div>
                )}
                <div style={{ height: '100%', width: '100%' }} ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes.map((node) => ({
                            ...node,
                            style: node.id === (selectedNode && selectedNode.id) ? selectedNodeStyle : nodeStyle,
                        }))}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onLoad={onLoad}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onNodeClick={onNodeClick}
                        onNodeDragStart={onNodeDragStart}
                        onNodeDragStop={onNodeDragStop}
                        style={{ height: '100%', width: '100%' }}
                        zoomOnScroll
                        zoomOnPinch
                        panOnScroll
                        zoomOnDoubleClick
                        minZoom={0.2}
                        maxZoom={2}
                    >
                        <Background />
                    </ReactFlow>
                </div>
            </div>
        </ReactFlowProvider>
    );
}