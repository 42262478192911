import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Terminal({ commands }) {
    const [displayedText, setDisplayedText] = useState('Bienvenue dans le terminal\n');
    const [input, setInput] = useState('');
    const [commandHistory, setCommandHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const navigate = useNavigate();
    const terminalEndRef = useRef(null);

    const scrollToBottom = () => {
        terminalEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleCommand = (command) => {
        const [cmd, ...args] = command.split(' ');
        if (commands[cmd]) {
            const output = commands[cmd].execute(args, navigate, clearTerminal);
            setDisplayedText(prev => prev + `\n${output}`);
        } else {
            setDisplayedText(prev => prev + `\nCommande inconnue : ${command}`);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisplayedText(prev => prev + `\n> ${input}`);
        handleCommand(input);
        setCommandHistory(prev => [...prev, input]);
        setHistoryIndex(-1);
        setInput('');
    };

    const clearTerminal = () => {
        setDisplayedText('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp') {
            if (commandHistory.length > 0) {
                let newIndex = historyIndex === -1 ? commandHistory.length - 1 : Math.max(historyIndex - 1, 0);
                while (newIndex > 0 && commandHistory[newIndex] === commandHistory[historyIndex]) {
                    newIndex = Math.max(newIndex - 1, 0);
                }
                setHistoryIndex(newIndex);
                setInput(commandHistory[newIndex]);
            }
        } else if (e.key === 'ArrowDown') {
            if (commandHistory.length > 0) {
                let newIndex = historyIndex === -1 ? -1 : Math.min(historyIndex + 1, commandHistory.length - 1);
                while (newIndex < commandHistory.length - 1 && commandHistory[newIndex] === commandHistory[historyIndex]) {
                    newIndex = Math.min(newIndex + 1, commandHistory.length - 1);
                }
                setHistoryIndex(newIndex);
                if (newIndex === -1 || newIndex === commandHistory.length) {
                    setInput('');
                } else {
                    setInput(commandHistory[newIndex]);
                }
            }
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [displayedText]);

    return (
        <div className="terminal">
            <pre>{displayedText}</pre>
            <form onSubmit={handleSubmit}>
                <span>&gt; </span>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="terminal-input"
                    spellCheck='false'
                />
            </form>
            <div ref={terminalEndRef} />
        </div>
    );
}