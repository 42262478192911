import React from 'react';

export default function ProjetPerso({ dataPersoProject }) {
    return (
        <div className='page'>
            <h2>Projets Personnels</h2>
            <br />
            <div className="contact-info">
                {dataPersoProject.projects.map((project, index) => (
                    <div key={index}>
                        <h3>{project.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: project.description }} />
                    </div>
                ))}
            </div>
        </div>
    );
}