import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import Home from './Home';
import CareerPath from './CareerPath';
import Contact from './Contact';
import Competence from './Compétences';
import ProjetPerso from './ProjetsPerso';

export default function MainPage({ commands, careerNodes, data, dataPersoProject }) {
  return (
    <div className="mainpage">
      <ReactFlowProvider>
        <Routes>
          <Route path="/" element={<Home commands={commands} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careerPath" element={<CareerPath careerNodes={careerNodes} />} />
          <Route path="/competence" element={<Competence data={data} />} />
          <Route path="/projetperso" element={<ProjetPerso dataPersoProject={dataPersoProject} />} />
        </Routes>
      </ReactFlowProvider>
    </div>
  );
}