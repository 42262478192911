import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import MainPage from './pages/MainPage';
import Canvas from './components/Canvas';
import './App.css';
import Terminal from './components/Terminal';

const commands = {
  navigate: {
    description: 'Naviguer vers une page spécifique. Utilisation : navigate <page>',
    execute: (args, navigate) => {
      const pages = {
        home: '/',
        contact: '/contact',
        career: '/careerPath',
        competence: '/competence',
        projetperso: '/projetperso'
      };
      if (args.length === 0) {
        return 'Utilisation : navigate <page>. Pour les pages disponibles, utilisez : navigate list';
      } else if (args[0] === 'list') {
        return 'Pages disponibles : ' + Object.keys(pages).join(', ');
      } else if (pages[args[0]]) {
        navigate(pages[args[0]]);
        return `Navigation vers ${args[0]}`;
      } else {
        return `Page inconnue : ${args[0]}. Pages disponibles : ` + Object.keys(pages).join(', ');
      }
    }
  },
  help: {
    description: 'Lister les commandes disponibles',
    execute: () => {
      return Object.keys(commands).map(cmd => `${cmd}: ${commands[cmd].description}`).join('\n');
    }
  },
  clear: {
    description: 'Effacer l\'écran du terminal',
    execute: (args, navigate, clearTerminal) => {
      clearTerminal();
      return '';
    }
  }
};

const careerNodes = [
  { label: 'Bac', description: 'Bac général avec mention assez bien, spécialités: science de l’ingénieur, math, math experte, spécialité abandonnée: science physique chimie, passé à Ugine.' },
  { label: 'But Info 1', description: 'Début du BUT informatique à Annecy.' },
  { label: "Travail d'été : La Sambuy", description: "Agent d’exploitation, missions: sécurité des activités (tir à la carabine, snow tubbing, tubby jump), fonctionnement du téléski pour VTT et du télésiège pour piétons (embarquement et débarquement)." },
  { label: 'But Info 2', description: 'Deuxième année du BUT informatique à Annecy.' },
  { label: 'Stage : INRAE', description: 'Développement d’un jeu vidéo sur le cycle de vie du corégone, un poisson vivant principalement dans le Léman.' },
  { label: "Travail d'été : La Sambuy", description: "Agent d’exploitation, missions: sécurité des activités (tir à la carabine, snow tubbing, tubby jump), fonctionnement du téléski pour VTT et du télésiège pour piétons (embarquement et débarquement)." },
  { label: 'But Info 3', description: 'Troisième année du BUT informatique à Annecy.' },
  { label: 'Stage : Ginkoia', description: 'Développement de POC en React JS pour des applications de vente destinées aux magasins de sport, principalement pour le ski et le VTT.' }
];

const data = {
  technical: [
    {
      competence: 'REALISER UN DEVELOPPEMENT D’APPLICATION',
      tasks: [
        'Développement d’une application web front en REACT',
        'A partir des applications existantes comprendre comment adapter son code et relire le code',
        'Travail avec la chaine CI/CD de Ginkoia',
      ],
    },
    {
      competence: 'OPTIMISER DES APPLICATIONS',
      tasks: [
        'Suite à des lenteurs bien anticipées, Alain est revenu avec une proposition de stockage dans indexedDB',
        'Travail sur le code existant d’une application de gestion des traductions comprendre les mécanismes et les adapter à une application web',
        'Mise en œuvre d’une bibliothèque de composant FRONT, choix de bibliothèque REACT',
      ],
    },
    {
      competence: 'COLLABORER AU SEIN D’UNE EQUIPE INFORMATIQUE',
      tasks: [
        'Suivi des diverses projets au sein de l’entreprise pour comprendre et voir comment utiliser des technos récentes comme REACT',
        'Pas forcement beaucoup travailler sur le sujet et notamment la publication sur une web app static au lieu d’une web app nodeJS aurait été un plus',
      ],
    },
  ],
  human: [
    'Aptitude à s’intégrer dans l\'équipe de travail',
    'Aptitude à s\'informer et à communiquer',
    'Autonomie, Esprit critique',
  ],
};

const dataPersoProject = {
  projects: [
    {
      title: "Backrooms France Map",
      description: "<p>Développement d'un <strong>site web</strong> utilisant <em>React</em> et <em>Node.js</em> pour créer un carte des dimensions des backrooms : <a rel='noreferrer' href='https://backrooms-france.map.alexsender.fr/' target='_blank'>https://backrooms-france.map.alexsender.fr/</a></p>"
    }
  ]
};

export default function App() {
  return (
    <>
      <div className="background">
        <Canvas />
      </div>
      <Router>
        <div className="app">
          <Header />
          <MainPage commands={commands} careerNodes={careerNodes} data={data} dataPersoProject={dataPersoProject} />
          <Terminal commands={commands} />
        </div>
      </Router>
    </>
  );
}