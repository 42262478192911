import React from 'react';

export default function Contact() {
    return (
        <div className='page'>
            <h2>Contact</h2>
            <div className="contact-info">
                <p>Téléphone : +33 7 78 31 14 84</p>
                <p>Email : wiklacz.alain@gmail.com</p>
                <p>Linkedin : <a rel="noreferrer" href='https://www.linkedin.com/in/alain-wiklacz/' target='_blank'>https://www.linkedin.com/in/alain-wiklacz/</a></p>
            </div>
        </div>
    );
}