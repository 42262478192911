import React from 'react';

export default function Competence({ data }) {
    const renderTechnicalCompetences = () => {
        return data.technical.map((item, index) => (
            <div key={index}>
                <h4>{item.competence}</h4>
                <ul>
                    {item.tasks.map((task, i) => (
                        <li key={i}>{task}</li>
                    ))}
                </ul>
            </div>
        ));
    };

    const renderHumanCompetences = () => {
        return data.human.map((competence, index) => (
            <div key={index}>
                <p>{competence}</p>
            </div>
        ));
    };

    return (
        <div className='page contact-info'>
            <h2>Compétences</h2>
            <br />
            <div>
                <h3>Compétences Techniques</h3>
                {renderTechnicalCompetences()}
            </div>
            <br />
            <div>
                <h3>Compétences Humaines</h3>
                {renderHumanCompetences()}
            </div>
        </div>
    );
}